html,
body {
  height: 100%;
  overflow: hidden;
  font-family: Roboto, sans-serif; }

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  background-color: #a32638; }
  header .wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 50px; }
  header .menu {
    display: flex;
    flex-direction: row; }
  header .menuitem {
    display: flex;
    flex-direction: row;
    height: calc(100% - 2px);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    padding-left: 12px;
    padding-right: 12px; }
  header .menuselect {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center; }
  header .menuitem:hover {
    background-color: #1b80de;
    text-decoration: none;
    cursor: pointer; }
  header div {
    display: block;
    color: white;
    text-align: center;
    line-height: 62px; }
  header div a:hover {
    background-color: #8d2f3d;
    text-decoration: none;
    cursor: pointer; }
  header .selected {
    border-bottom: 2px solid #ffffff; }
  header img {
    display: block;
    margin: auto;
    height: 30px; }

.preferencesWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between; }

.innerPreferencesWrapper {
  display: flex;
  flex-direction: column;
  overflow: auto; }

.content {
  padding: 70px 0px 0px 0px;
  height: 100%;
  background-color: #f5f5f5;
  overflow: hidden; }

.full {
  width: 100%;
  height: 100%; }

.codePaper {
  width: 100%;
  height: calc(100% - 8px); }

#codeDiv {
  height: calc(100% - 48px); }

.codeItem {
  padding-bottom: 8px;
  padding-right: 10px;
  padding-left: 10px;
  flex: 1 1 0%; }

#ColorPicker {
  padding: 10px; }

.mainGrid {
  height: 100%; }

.codeGrid {
  height: 100%;
  width: 400px; }

.svgGrid {
  height: calc(100% - 300px);
  width: 100%; }

.preferencesGrid {
  height: 100%;
  width: 300px; }

.legendGrid {
  height: 300px;
  width: 100%; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */ }

.preferenceItem {
  padding-right: 10px; }

.inputElement {
  width: calc(100%); }

.formselect {
  margin-top: 16px;
  margin-bottom: 8px; }

.paddedButton {
  padding-bottom: 8px;
  padding-right: 10px; }

.legendItem {
  width: 200px;
  height: 50px;
  margin: 10px;
  line-height: 50px;
  text-align: center;
  color: black; }

.reflex-size-aware {
  overflow: hidden; }

.ace_scrollbar {
  display: none !important; }

.ace_editor {
  background: rgba(0, 0, 0, 0) !important; }

.ace_gutter {
  background: #f5f5f5 !important;
  z-index: auto !important; }

svg text {
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

#loader {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center; }

.spinner {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #2196f3;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.updateButtonContainer {
  display: flex; }

.dropzoneContainer {
  border: #f3f3f3;
  border-style: dashed;
  padding: 5px;
  border-radius: 4px; }
